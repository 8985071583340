<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Emisión por lotes" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn v-if="$helpers.hasSomePermission(['add_batch'])" @click="dialogCreate=true" color="primary" :ripple="false"><v-icon left>mdi-plus-box</v-icon>Crear proceso</v-btn>
      </template>
      <template v-slot:custom-tab>
        <v-tabs v-if="$helpers.hasSomePermission(['view_batch'])" v-model="filter" class="mt-n3 pt-8 pl-12 pr-0 background" :height="35" style=" position: absolute; z-index: 0 !important">
          <v-tab v-for="status in stepperList" :key="status.id" :ripple="false">
            <v-icon left small>mdi-{{status.icon}}</v-icon>{{status.title}}
            <template v-if="status.title === 'Todas'"></template>
            <!-- ({{status.title === 'Todas' ? backupCount : status.count}}) -->
          </v-tab>
        </v-tabs>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0 mt-25">
      <v-col class="px-0" v-if="$store.state.batch.retrieveLoader">
        <skeleton-list />
      </v-col>
      <template v-else>
        <PermissionDenied v-if="!$helpers.hasSomePermission(['view_batch'])" />
        <template v-else>
          <v-col class="px-0 pb-16 mb-5" v-if="documentsList.length">
            <v-row>
              <v-col v-for="d in documentsList" :key="d.title" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                <v-hover v-slot="{ hover }">
                  <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="goTo(d.id)">
                    <span class="d-block body-1 font-weight-bold fontBody--text">
                      {{d.count}} documentos
                    </span>
                    <span class="d-block body-2 fontDraw--text d-inline-block text-truncate" style="max-width: 99%;">ID {{d.id}}</span>
                      <v-chip class="mt-8" :color="d.status.search(/ing/) !== -1  ? 'yellowLight' : 'lightBlue'" small label>
                        {{stepperList.find((s) => s.status.includes(d.status)).title}}
                        <v-icon right>mdi-{{stepperList.find((s) => s.status.includes(d.status)).icon}}</v-icon>
                      </v-chip>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-col class="pa-0" v-if="showPagination || showAll">
              <VMainPagination :count="count" />
            </v-col>
          </v-col>
          <v-empty-state v-else type="documents" id="documentos" :isFree="true" customClass="mt-n8" title="No hay proceso de facturación por lote" />
        </template>
      </template>
    </v-col>
    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" :width="widthModalCreateErrors" persistent no-click-animation>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">{{ !errors.length ? 'Crear proceso de facturación por lotes'
            : 'Error al cargar el archivo' }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreate=false, errors=[], clearInput()" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-5 py-4">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-${errors.length ? 'error' : 'create'}-fl.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-0 pl-6">
              <v-list class="v-list-form pa-0 transparent" v-if="!errors.length">
                <v-list-item class="py-0">
                  <v-list-item-title>Fuente de datos</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-file-input v-model="file" :error="errorFile" ref="file" @change="setUploadFile()" type="file" accept=".xlsx" prepend="mdi-paper-clip" hide-details background-color="transparent" placeholder="No se eligió ningún archivo" solo flat chips dense />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2">
                  <v-list-item-title>Formato</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-select v-model="$v.newBatch.import_format.$model" :error="$v.newBatch.import_format.$error" :items="importFormats" readonly disabled item-text="importFormats" item-value="importFormats" hide-details single-line dense height="30" outlined />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
              <v-col class="pa-0" v-else>
                <span class="d-block error--text subtitle-1 font-weight-bold"><v-icon class="mr-4" color="error">mdi-alert</v-icon>El documento contiene errores</span>
                <span class="d-block mt-4 body-2 fontBody--text">
                  El archivo fuente cargado contiene registros con errores. Puedes continuar con el proceso por lote, y volver a cargar el resto iniciando un nuevo proceso, o puedes abortar el proceso actual e iniciar uno nuevo considerando los errores reportados.
                </span>
              </v-col>
              <!-- <v-simple-table v-else class="table-detail" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Doc. #</th>
                  <th class="text-left">Columna</th>
                  <th class="text-right">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="error in errors" :key="error.number">
                  <td>{{error.number}}</td>
                  <td>{{error.col}}</td>
                  <td class="text-right">{{error.description}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn v-if="!errors.length" @click="dialogCreate=false, clearInput()" outlined color="secondary">Cancelar</v-btn>
          <v-btn v-if="errors.length" @click="errors=[], clearInput()" outlined color="secondary">Volver a subir</v-btn>
          <v-btn @click="!errors.length ? createDocumentsBulk() : handleDialog()" :loading="!errors.length ? $store.state.batch.createLoader : false" :disabled="(!!errorFile || !((file || {}).name || '').length)" color="primary">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create -->
  </v-row>
</template>
<script>
  import { mapState } from 'vuex'
  import { required } from 'vuelidate/lib/validators'
  import MiniHeader from '@/components/commons/MiniHeader'
  import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
  import VEmptyState from '@/components/commons/VEmptyState'
  import ListViewMixin from '@/mixins/ListViewMixin'
  import VMainPagination from '@/components/commons/VMainPagination'
  import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'

  export default {
    components: {
      VQueryBuilder,
      VEmptyState,
      SkeletonList,
      VMainPagination,
      PermissionDenied,
      MiniHeader
    },
    mixins: [
      ListViewMixin
    ],
    data: () => ({
      env: process.env?.VUE_APP_MODE ?? 'ax',
      errorFile: false,
      scroll: 0,
      errors: [],
      file: null,
      newBatch: {
        file: null,
        import_format: 'xlsx'
      },
      importFormats: ['csv', 'xlsx', 'json'],
      filter: 0,
      dialogCreate: false,
      stepperList: [
        {
          id: 0,
          status: [],
          title: 'Todas',
          icon: 'ballot'
          // width: 'auto'
        },
        {
          id: 1,
          status: ['pending'],
          title: 'Previsualizar',
          icon: 'file-search'
          // width: 130
        },
        {
          id: 2,
          status: ['generating', 'generated'],
          title: 'Generar',
          icon: 'cog'
          // width: 100
        },
        {
          id: 3,
          status: ['processing', 'processed'],
          title: 'Procesar',
          icon: 'draw'
          // width: 110
        },
        {
          id: 4,
          status: ['declaring', 'declared'],
          title: 'Declarar',
          icon: 'bank'
          // width: 110
        },
        {
          id: 5,
          status: ['distributing', 'distributed'],
          title: 'Distribuir',
          icon: 'send-clock'
          // width: 'auto'
        },
        {
          id: 6,
          status: ['finalized'],
          title: 'Completado',
          icon: 'check'
        }
      ],
      breadcrumbs: {
        main: 'Emisión por lotes',
        children: [
          {
            text: 'Procesos'
          }
        ]
      }
    }),
    computed: {
      ...mapState({
        count: state => state.batch.batchCount,
        documentsList: state => state.batch.batchList
      }),
      widthModalCreateErrors () {
        return this.errors.length ? '715' : '578'
      }
    },
    watch: {
      filter (val) {
        if (val > 0) {
          let query = Object.values(this.stepperList.find((item) => item.id === val).status).toString()
          this.$router.replace({ params: this.$route.params, name: this.$route.name, query: {status__in: query} }).catch(() => {})
        } else this.$router.replace({ params: this.$route.params, name: this.$route.name, query: null }).catch(() => {})
      },
      $route () {
        this.getListBatch()
      }
    },
    created () {
      if (this.$route.query.status__in) {
        const index = this.stepperList.findIndex((item) => this.$route.query.status__in.split(',').every((s) => item.status?.includes(s)))
        if (index > 0) this.filter = index
      }
      this.$store.dispatch('batch/LIST', {
        resource: 'batch',
        query: this.$route.query
      })
      .then((response) => {
        // const list = Object.entries(groupBy(response.data.results, 'status')).map(([key, value]) => {
        //   return { status: key, count: value.length }
        // })
        // list.forEach((item) => {
        //   const index = this.stepperList.findIndex((s) => s.status.includes(item.status))
        //   if (index !== -1) {
        //     this.stepperList[index].count = !!response.data.next
        //   }
        // })
      })
    },
    methods: {
      goTo (id = null) {
        this.$store.commit('base/SET_QUERY', this.$route.query)
        this.$router.push({ name: 'DocumentsBatchRetrieve', params: { id: id }})
      },
      setUploadFile () {
        this.errorFile = this.file?.name?.search('xlsx') === -1 ?? false
      },
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      getListBatch () {
        this.$store.dispatch('batch/LIST', {
          resource: 'batch',
          query: this.$route.query
        })
      },
      createDocumentsBulk (error = false) {
        this.newBatch.file = this.file
        this.$v.newBatch.$touch()
        if (this.$v.newBatch.$invalid) {
          return false
        }
        const payload = new FormData()
        payload.append('source', this.newBatch.file, this.newBatch.file.name)
        payload.append('import_format', this.newBatch.import_format)
        payload.append('error_load', error)
        this.$store.dispatch('batch/CREATE', {
          resource: 'batch',
          payload: payload,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (this.$helpers.hasSomePermission(['view_batch'])) this.$router.push({name: 'DocumentsBatchRetrieve', params: {id: response.data.id, ...this.$route.params}})
          else {
            this.dialogCreate = false
            this.$dialog.message.info('El proceso se ha creado con éxito')
          }
        })
        .catch((error) => {
          this.errors = error.response.data
          // error.response.data.forEach((err) => {
          //   for (let i = 0; i < Object.keys(err).length; i++) {
          //     Object.entries(Object.values(err)[i][0]).forEach(([k, v]) => {
          //       console.log(Object.values(v)[0]?.toString() ?? '')
          //       arrayError[i] = {
          //         number: i + 1,
          //         col: Object.keys(v)?.toString() ?? '',
          //         description: Object.values(v)[0]?.toString() ?? ''
          //       }
          //     })
          //   }
          // })
          // this.errors = arrayError?.filter((item) => item.col.length)
        })
      },
      clearInput () {
        this.file = null
        this.newBatch.import_format = 'xlsx'
        this.newBatch.file = null
        this.$v.newBatch.$reset()
      },
      handleDialog () {
        this.createDocumentsBulk(true)
        this.dialogCreate = false
        this.errors = []
        this.clearInput()
      }
    },
    validations: {
      newBatch: {
        file: {
          required
        },
        import_format: {
          required
        }
      }
    }
  }
</script>